<template>
  <div class="flex justify-center h-full w-full">
    <div class="w-full sm:w-max-3xl">
      <div v-if="store" class="w-full flex flex-col gap-4">
        <div class="flex flex-row justify-between items-center">
          <span class="text-2xl font-bold">Plans</span>
          <vs-button to="/store/createPlan" class="ml-2">
            Create Plan
          </vs-button>
        </div>

        <PlansTable :store="store" />
      </div>
    </div>
  </div>
</template>

<script>
import { createStore, getMyStore } from "@/services/ApiService";
import PlansTable from "@/views/seller/plans/PlansTable";
export default {
  name: "Plans",
  components: { PlansTable },
  data() {
    return {
      store: undefined,
      name: "",
      storeCreateLoading: false,
    };
  },
  methods: {
    onCreateStoreButtonClick() {
      this.storeCreateLoading = true;
      createStore({ name: this.name }).then(() => {
        this.storeCreateLoading = false;
        this.name = "";
        this.getMyStore();
      });
    },
    async getMyStore() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });
      const store = await getMyStore();
      if (store) {
        this.store = store;
      } else {
        this.store = null;
      }
      loading.close();
    },
  },
  async created() {
    await this.getMyStore();
  },
};
</script>

<style scoped>

</style>
