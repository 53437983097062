<template>
  <div class="w-full">
    <vs-table class="w-auto relative" ref="table" v-model="selected">
      <template #thead>
        <vs-tr>
          <vs-th class="!w-2">
            <i class="bx bx-check" />
          </vs-th>
          <vs-th class="!w-1/5"> Product </vs-th>
          <vs-th> Duration </vs-th>
          <vs-th> Price </vs-th>
          <vs-th> Slot </vs-th>
          <vs-th> Created At</vs-th>
        </vs-tr>
      </template>
      <template #tbody v-if="plans">
        <vs-tr
          :key="i"
          v-for="(pl, i) in plans.docs"
          :data="pl"
          class="cursor-pointer"
          :is-selected="selected === pl"
        >
          <vs-td>
            <i v-if="checkActive(pl)" class="bx bx-x"></i>
            <i v-else class="bx bx-check"></i>
          </vs-td>
          <vs-td>
            {{
              pl.product &&
              (pl.product.name.length > NAME_MAX_LENGTH
                ? pl.product.name.substring(0, NAME_MAX_LENGTH) + "..."
                : pl.product.name)
            }}
          </vs-td>
          <vs-td> {{ pl.name }}</vs-td>
          <vs-td> {{ pl.price }} $</vs-td>
          <vs-td> {{ pl.fulfilledSlots }}/{{ pl.slot }}</vs-td>
          <vs-td>
            {{ pl.createdAt | formatTimestamp }}
          </vs-td>
        </vs-tr>
      </template>
      <template #footer v-if="plans">
        <vs-pagination v-model="page" :length="plans.totalPages" />
      </template>
    </vs-table>
  </div>
</template>

<script>
import { getPlansByStore } from "@/services/ApiService";

export default {
  name: "PlansTable",
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    page: 1,
    max: 10,
    plans: null,
    selected: null,
    NAME_MAX_LENGTH: 25,
  }),
  methods: {
    async getPlans() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.plans = await getPlansByStore(this.store._id, this.page)
        .then((response) => {
          return response;
        })
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
    checkActive(plan) {
      return plan.product.deleted || !plan.active;
    },
  },
  async mounted() {
    await this.getPlans();
  },
  watch: {
    page() {
      this.getPlans();
    },
    selected: {
      handler() {
        const id = this.selected._id;
        this.$router.push({
          name: "createPlan",
          params: { id },
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
